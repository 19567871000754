import React from 'react';
import TeamTabHeading from './teamTabHeading';
import TeamTabSubHeading from './teamTabSubHeading';

const TeamTab = ({
  index,
  setCurrentTab,
  title,
  subtitle,
  currentTab,
  subtitle2,
}) => {
  return (
    <div
      className={`flex flex-col items-start cursor-pointer hover:text-primary`}
      onClick={() => setCurrentTab(index)}
    >
      <TeamTabHeading
        text={title}
        extraClassNames={currentTab === index ? 'text-primary' : ''}
      />
      {currentTab === index && <TeamTabSubHeading text={subtitle} />}
      {currentTab === index && subtitle2 && (
        <TeamTabSubHeading text={subtitle2} />
      )}
      {currentTab === index && <div className='h-64 ' />}
    </div>
  );
};

export default TeamTab;
