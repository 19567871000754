import React from 'react';

const TeamTabHeading = ({ text, extraClassNames }) => {
  return (
    <h1
      className={`font-semibold text-xl md:text-2xl lg:text-3xl pb-1 ${extraClassNames} `}
    >
      {text}
    </h1>
  );
};

export default TeamTabHeading;
