import React from 'react';

const TeamTabSubHeading = ({ text, extraClassNames }) => {
  return (
    <h1
      className={` text-base md:text-lg lg:text-xl font-semibold  ${extraClassNames} text-primary`}
    >
      {text}
    </h1>
  );
};

export default TeamTabSubHeading;
