import React, { useEffect, useState } from 'react';

import CommonLayout from '../components/home/commonLayout';
import { Contents } from '../data/contents';
import Layout from '../components/layout';
import PharmCorpHeading from '../components/typography/heading';
import SEOTag from '../components/seo/seo';
import TeamTab from '../components/team/teamTab';
import member1 from '../images/member1.jpeg';
import member2 from '../images/member2.png';
import member3 from '../images/member3.jpeg';
import toLower from 'lodash/toLower';

const images = [member1, member2, member3];

const TeamPage = ({ location }) => {
  const [currentTab, setCurrentTab] = useState(0);

  const getSelectedFounder = (query) => {
    if (query) {
      const qp = new URLSearchParams(query);
      switch (toLower(qp.get('founder'))) {
        case 'jeorge':
          setCurrentTab(0);
          break;
        case 'jagger':
          setCurrentTab(1);
          break;
        case 'jose':
          setCurrentTab(2);
          break;
        default:
          setCurrentTab(0);
          break;
      }
    }
  };

  useEffect(() => {
    getSelectedFounder(location.search);
  }, []);

  const handleClickTab = (index) => {
    setCurrentTab(index);
  };

  return (
    <Layout selectedMenu='team'>
      <SEOTag title='The Team | Pharmcorps' />
      <CommonLayout>
        <PharmCorpHeading
          lightText='Meet'
          boldText='Our Team'
          underLined={true}
        />
        <div className='h-2 mx-auto mb-10'></div>
        <div className='flex flex-row pl-0 md:pl-8'>
          <div className='flex flex-col w-1/2 items-start mr-1 md:mr-2'>
            <TeamTab
              title='Jorge Barquin, PharmD'
              subtitle='Founder,'
              subtitle2='President & CEO'
              index={0}
              currentTab={currentTab}
              setCurrentTab={handleClickTab}
            />
            <div className='h-0.5 bg-black w-10/12 my-2' />
            <TeamTab
              title='Jagger Esch'
              subtitle='Co-Founder'
              index={1}
              currentTab={currentTab}
              setCurrentTab={handleClickTab}
            />
            <div className='h-0.5 bg-black w-10/12 my-2' />
            <TeamTab
              title='Jose Barquin Jr., MD'
              subtitle='Co-Founder'
              index={2}
              currentTab={currentTab}
              setCurrentTab={handleClickTab}
            />
          </div>
          <div className='w-full'>
            <div className='w-48 h-48 md:w-60 md:h-60 lg:w-80 lg:h-80 float-left overflow-hidden shadow-md rounded-lg mr-6 mb-5 md:mb-0'>
              <img
                className='min-w-full min-h-full object-cover '
                src={images[currentTab]}
                alt='Person'
              />
            </div>
            <div className='text-justify text-gray-500 '>
              {Contents.team[currentTab].map((row, index) => {
                return (
                  <div key={index}>
                    {row}

                    {index !== Contents.team[currentTab].length && <br />}
                    {index !== Contents.team[currentTab].length && <br />}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </CommonLayout>
      <div className='h-5 md:h-10' />
    </Layout>
  );
};

export default TeamPage;
